<template>
  <v-layout column>
    <validation-observer ref="observer">
      <v-form @submit.prevent="submit">
        <v-layout column class="border-primary rounded-lg px-8 pt-4">
          <v-layout>
            <v-layout column col-6 class="pr-6 py-0">
              <label class="text-subtitle-1">
                {{ $t('full_name') }}
                <span class="red--text">{{ $t('required') }}</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="textRequired"
                vid="full_name"
                :rules="requiredRules"
              >
                <v-text-field
                  v-model="fullName"
                  outlined
                  dense
                  filled
                  disabled
                  class="rounded-lg"
                  :maxlength="maximumSmallText"
                  :error-messages="errors"
                  :placeholder="$t('place_holders.employee_fullname')"
                />
              </validation-provider>
            </v-layout>
            <v-layout column col-6 class="pl-6 py-0">
              <label class="text-subtitle-1">
                {{ $t('employee_code') }}
              </label>
              <v-text-field
                v-model="code"
                outlined
                dense
                filled
                disabled
                class="rounded-lg"
                :maxlength="maximumSmallText"
                :placeholder="$t('place_holders.employee_code')"
              />
            </v-layout>
          </v-layout>
          <v-layout>
            <v-layout column col-6 class="pr-6 py-0">
              <label class="text-subtitle-1">
                {{ $t('phone') }}
                <span class="red--text">{{ $t('required') }}</span>
              </label>
              <validation-provider v-slot="{ errors }" name="phone">
                <v-text-field
                  v-model="phone"
                  outlined
                  dense
                  filled
                  disabled
                  class="rounded-lg"
                  :maxlength="maximumPhone"
                  :error-messages="errors"
                  :placeholder="$t('place_holders.phone')"
                  @keypress="isNumber($event)"
                />
              </validation-provider>
            </v-layout>
            <v-layout column col-6 class="pl-6 py-0">
              <label class="text-subtitle-1">
                {{ $t('email') }}
                <span class="red--text">{{ $t('required') }}</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="email"
                :rules="emailRules"
              >
                <v-text-field
                  v-model="email"
                  outlined
                  dense
                  filled
                  disabled
                  class="rounded-lg"
                  :maxlength="maximumEmail"
                  :error-messages="errors"
                  :placeholder="$t('place_holders.email')"
                />
              </validation-provider>
            </v-layout>
          </v-layout>
          <v-layout>
            <v-layout column col-6 class="pr-6 py-0">
              <label class="text-subtitle-1">
                {{ $t('regency') }}
                <span class="red--text">{{ $t('required') }}</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="selectRequire"
                vid="regency"
                :rules="requiredRules"
              >
                <v-select
                  v-model="regencySelected"
                  outlined
                  dense
                  multiple
                  disabled
                  class="rounded-lg"
                  item-text="name"
                  item-value="code"
                  :placeholder="$t('place_holders.regency')"
                  :items="regencyItems"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-layout>
            <v-layout column col-6 class="pl-6 py-0">
              <label class="text-subtitle-1">
                {{ $t("post_offices") }}
              </label>
              <v-text-field
                v-model="postOffice"
                outlined
                dense
                filled
                disabled
                class="rounded-lg"
                :maxlength="maximumEmail"
                :placeholder="$t('place_holders.employee_code')"
              />
            </v-layout>
          </v-layout>
        </v-layout>

        <v-layout justify-space-between class="mt-4">
          <v-btn
            outlined
            dense
            color="primary"
            class="rounded-lg mr-4"
            @click="onGotoChangePassword"
          >
            {{ $t('change_password') }}
          </v-btn>

          <!-- <v-btn
            v-if="isShowAction"
            color="primary"
            type="submit"
            class="rounded-lg"
            :disabled="invalid"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-layout>
      </v-form>
    </validation-observer>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :persistent="persistent"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </v-layout>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import constants from '@/constants'
import { StringUtils } from '@/helpers/stringUtils'
import { FormUtils } from '@/helpers/formUtils'
import routePaths from '@/router/routePaths'
import { StorageService } from '@/services/storageService'
import { UserService } from '@/services/userService'
import { mapActions } from 'vuex'

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried
  },
  data() {
    return {
      requiredRules: {
        required: true
      },
      fullName: '',
      maximumSmallText: constants.maximumSmallText,
      code: '',
      phone: '',
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        min: constants.minimumPhone,
        max: constants.maximumPhone,
        regex: constants.regExp.phone
      },
      email: '',
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email
      },
      regencySelected: '',
      regencyItems: [],

      showNoti: false,
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      message: '',
      showLoading: false,
      showConfirmTokenExpried: false,
      postOffice: ''
    }
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    },
    isShowAction() {
      const currUser = StorageService.getCurrentUser()
      const userRoles = currUser.roles
      return !userRoles.includes(constants.roleRoot)
    }
  },
  created() {},
  beforeMount() {
    this.initData()
  },
  methods: {
    ...mapActions('layout', ['setDrawer', 'setBreadcrumbs', 'setFullName']),
    async initData() {
      await this.getRoles()
      await this.getUserInfo()
    },
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        this.showLoading = true
        const pars = this.bindRequestPars()
        const { status, data } = await UserService.employeeUpdate(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok && data) {
          const currUser = StorageService.getCurrentUser()
          currUser.name = data.name
          StorageService.setName({ currentUser: currUser })
          this.setFullName(data.name)
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t('success_saved_info')
          })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    isPermissionUpdateAndDelete() {},
    bindRequestPars() {
      const pars = {
        id: StorageService.getUserId(),
        name: this.fullName.trim(),
        code: this.code,
        role: this.regencySelected
      }

      return pars
    },
    async getUserInfo() {
      this.showLoading = true
      const pars = {
        usersId: StorageService.getUserId()
      }
      const { status, data } = await UserService.employeeGetInfo(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.parseFormData(data)
      } else {
        if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    async parseFormData(data) {
      this.fullName = data.name
      this.code = data.code
      this.phone = data.phone
      this.email = data.email
      this.regencySelected = data.roles
      this.postOffice = data.poName
    },
    async getRoles() {
      this.showLoading = true
      const { status, data } = await UserService.getRoles()
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.regencyItems = data
      } else {
        if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    onGotoChangePassword() {
      this.$router.push(routePaths.CHANGE_PASSWORD)
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: ''
      }
    ) {
      this.showNoti = pars.state
      this.persistent = pars.persistent
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/createMultiOrder.scss" lang="scss">
</style>
