var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-layout',{staticClass:"border-primary rounded-lg px-8 pt-4",attrs:{"column":""}},[_c('v-layout',[_c('v-layout',{staticClass:"pr-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('full_name'))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('required')))])]),_c('validation-provider',{attrs:{"name":"textRequired","vid":"full_name","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":"","disabled":"","maxlength":_vm.maximumSmallText,"error-messages":errors,"placeholder":_vm.$t('place_holders.employee_fullname')},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})]}}])})],1),_c('v-layout',{staticClass:"pl-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('employee_code'))+" ")]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":"","disabled":"","maxlength":_vm.maximumSmallText,"placeholder":_vm.$t('place_holders.employee_code')},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),_c('v-layout',[_c('v-layout',{staticClass:"pr-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('phone'))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('required')))])]),_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":"","disabled":"","maxlength":_vm.maximumPhone,"error-messages":errors,"placeholder":_vm.$t('place_holders.phone')},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])})],1),_c('v-layout',{staticClass:"pl-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('email'))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('required')))])]),_c('validation-provider',{attrs:{"name":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":"","disabled":"","maxlength":_vm.maximumEmail,"error-messages":errors,"placeholder":_vm.$t('place_holders.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1)],1),_c('v-layout',[_c('v-layout',{staticClass:"pr-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('regency'))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('required')))])]),_c('validation-provider',{attrs:{"name":"selectRequire","vid":"regency","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","multiple":"","disabled":"","item-text":"name","item-value":"code","placeholder":_vm.$t('place_holders.regency'),"items":_vm.regencyItems,"error-messages":errors},model:{value:(_vm.regencySelected),callback:function ($$v) {_vm.regencySelected=$$v},expression:"regencySelected"}})]}}])})],1),_c('v-layout',{staticClass:"pl-6 py-0",attrs:{"column":"","col-6":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("post_offices"))+" ")]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","filled":"","disabled":"","maxlength":_vm.maximumEmail,"placeholder":_vm.$t('place_holders.employee_code')},model:{value:(_vm.postOffice),callback:function ($$v) {_vm.postOffice=$$v},expression:"postOffice"}})],1)],1)],1),_c('v-layout',{staticClass:"mt-4",attrs:{"justify-space-between":""}},[_c('v-btn',{staticClass:"rounded-lg mr-4",attrs:{"outlined":"","dense":"","color":"primary"},on:{"click":_vm.onGotoChangePassword}},[_vm._v(" "+_vm._s(_vm.$t('change_password'))+" ")])],1)],1)],1),_c('dialog-loading',{attrs:{"message":_vm.$t('processing')},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}),_c('dialog-notification',{attrs:{"persistent":_vm.persistent,"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}}),_c('dialog-confirm-token-expried',{model:{value:(_vm.showConfirmTokenExpried),callback:function ($$v) {_vm.showConfirmTokenExpried=$$v},expression:"showConfirmTokenExpried"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }